import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/main"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data

  const { frontmatter, html } = markdownRemark

  return (
    <Layout title={frontmatter.title}>
      <div className="">
        <div className="flex flex-col my-4 space-y-2">
          <h1>{frontmatter.title}</h1>
          <div className="text-sm">{frontmatter.date}</div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
